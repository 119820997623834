<template>
  <v-expansion-panels flat v-if="!flatten">
  <v-expansion-panel active-class="myactivepanel">
    <v-expansion-panel-header class="mypanelheader" expand-icon="mdi-menu-down">{{filter.title }}</v-expansion-panel-header>
    <v-expansion-panel-content class="mypanelcontent">
        <FilterComponentRange v-if="filter.range" :filter="filter"/>
        <FilterComponentCampaign v-else-if="filter.extName==='cf'"  :filter="filter"/>
        <FilterComponentDateRef v-else-if="filter.dateRef" :filter="filter"/>
        <FilterComponentDateInterval v-else-if="filter.dateInterval" :filter="filter"/>
        <FilterComponentRadios v-else-if=" !filter.multipleSelections"  :filter="filter"/>
        <FilterComponentChecks v-else :filter="filter"/>
    </v-expansion-panel-content>
  </v-expansion-panel>
  </v-expansion-panels>

  <div v-else>
    <FilterComponentRange v-if="filter.range" :filter="filter"/>
    <FilterComponentDateRef v-else-if="filter.dateRef" :filter="filter"/>
    <FilterComponentRadios v-else-if="!filter.multipleSelections" :filter="filter"/>
    <FilterComponentChecks v-else :filter="filter"/>
  </div>
</template>

<script>
import FilterComponentRadios from "./FilterComponentRadios";
import FilterComponentChecks from "./FilterComponentChecks";
import FilterComponentRange from "@/components/FilterComponentRange";
import FilterComponentDateRef from "./FilterComponentDateRef";
import FilterComponentCampaign from "@/components/FilterComponentCampaign.vue";
import FilterComponentDateInterval from "@/components/FilterComponentDateInterval.vue";

export default {
  name: 'FilterComponent',

  components: {
    FilterComponentDateInterval,
    FilterComponentCampaign,
    FilterComponentDateRef, FilterComponentRange, FilterComponentRadios, FilterComponentChecks},

  data () {
    return {}
  },

  props: {
    filter: Object,
    flatten: Boolean
  }

}
</script>

<style>
.myactivepanel {
  color: #0000C8 !important;
}

.myactivepanel button div i {
  color: #0000C8 !important;
}

.v-expansion-panel::before {
  box-shadow: none !important;
}

.v-expansion-panel button {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.mypanelheader {
  /*font-size: 13.5px !important;*/
  font-size: calc((13.5/16)*1rem) !important;

  padding-left: 0 !important;
  padding-right: 0 !important;
  flex-direction: row-reverse;
  justify-content: flex-end;
  /*justify-content: space-between;*/
  padding-bottom: 0 !important;
}

.mypanelheader div:first-child {
  margin-left: 0 !important;
  margin-right: 5px !important;
}



.mypanelcontent>div {
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  margin: 0 !important;
}

.mypanelcontent div {
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin: 0 !important;
}


</style>
